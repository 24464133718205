import { Injectable } from '@angular/core';

@Injectable()
export class Tools {
  buildUrl(param: any[]) {
    if (param && param.length > 0) {
      return param.join('/').toString();
    } else {
      return param.toString();
    }
  }
}

export class Address {
  FirstName = '';
  LastName = '';
  Email = '';
  Courier = '';
  CourierId = '';
  Country = '';
  CountryCode = '';
  State = '';
  StateId = '';
  City = '';
  CityId = '';
  Address1 = '';
  Address2 = '';
  PostalCode = '';
  PhoneNumber = '';
  FaxNumber = '';
  Notes = '';
}

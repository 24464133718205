import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(public globals: Globals) {}

  year = 0;
  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }
}

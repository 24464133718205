import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../_models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../_services/auth.service';
import { ProductService } from '../_services/product.service';
import { CartService } from '../_services/cart.service';
declare let fbq: Function;

@Component({
  selector: 'app-catalog-grid-view',
  templateUrl: './catalog-grid-view.component.html',
  styleUrls: ['./catalog-grid-view.component.scss'],
})
export class CatalogGridViewComponent implements OnInit {
  @Input() product: Product;
  isUpdating = false;
  isLoaded = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private productService: ProductService,
    public cartService: CartService,
  ) {}

  ngOnInit(): void {}

  addItem(product: Product) {
    fbq('track', 'AddToCart', {
      content_ids: [this.product.Id],
      content_name: this.product.Name,
      value: this.product.Price,
      content_type: 'product',
      currency: this.globals.Store.Currency,
    });
    this.isUpdating = true;
    this.cartService.addToCart(product).finally(() => {
      this.isUpdating = false;
    });
  }

  changeCategory(category: number) {
    this.globals.Store.SelectedCategory = category;
    // this.refresh();
  }

  showProduct(productId: number) {
    this.globals.goToUrl(this.router, 'products/' + productId);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Globals } from '../globals';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public vendorName = new FormControl();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals) {}

  ngOnInit() {}

  changeVendor() {
    this.router.navigate([this.vendorName.value]);
  }
}

<div class="card border-0" style="padding:10px 15px;">
  <div class="img-container">
    <div class="square">
      <img title="{{product.Name}}"	loading="lazy" class="product-img" alt="{{product.Name}}" data-src="{{'noimage.png' | imageUrl}}"
        *ngIf="!product.ImageUrl" />
      <img title="{{product.Name}}"	loading="lazy" class="product-img" alt="{{product.Name}}" data-src="{{product.ImageUrl | imageUrl}}"
        *ngIf="product.ImageUrl" (click)="showProduct(product.Id)" />
    </div>
  </div>
  <div>
    <p class="product-name m-0" (click)="showProduct(product.Id)">
      {{product.Name}}
    </p>
  </div>
  <div class="row" style="min-height: 36px;">
    <div class="col-12 col-lg-6">
      <div *ngIf="product.ListPrice > 0">
        <span class="previousPrice strikethrough">{{ globals.Store.Currency }} {{product.ListPrice | number:'1.2-2' }}</span>
      </div>
      <div>
        <span class="price" [style.color]="globals.Store.VendorConfig.PriceColor">{{ globals.Store.Currency }} {{product.Price | number:'1.2-2' }}</span>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="add-cart m-0" *ngIf="!isUpdating">
        <button class="w-100 p-0" mat-raised-button [style.backgroundColor]="globals.Store.VendorConfig.ButtonBackgroundColor"
          [style.color]="globals.Store.VendorConfig.ButtonForegroundColor" (click)="addItem(product)">ADD TO CART</button>
      </div>
    </div>
  </div>

</div>
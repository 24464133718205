<ng-container *ngIf="pageLoaded">
  <main role="main" class="main-container">
    <app-header></app-header>
    <div class="wrapper">
      <div class="content-container">
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </main>
</ng-container>

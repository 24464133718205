import { Globals } from './globals';
import { Injectable, Component, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CheckoutComponent } from './checkout/checkout.component';

@Injectable()
export class SaveEditGuard implements CanDeactivate<CheckoutComponent> {
  private sub: Subscription;
  private result: boolean;

  @ViewChild('dialog')
  private dialog: TemplateRef<any>;

  constructor(private router: Router, private matDialog: MatDialog, private global: Globals) {}

  canDeactivate(
    component: CheckoutComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    if (this.global.Store.isCheckedOut) {
      this.global.Store.isCheckedOut = false;
      return true;
    }
    const confirmation = confirm('Are you sure you want to leave this page? All data will be removed');
    if (confirmation) {
      this.global.clearForm();
    }
    return confirmation;
  }
}

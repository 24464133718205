import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { CartItem } from './../_models/cart-item';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css'],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(public globals: Globals, private readonly router: Router) {}

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe({
      next: (event) => {
        const isEnd = event instanceof NavigationEnd;
        console.log(isEnd);
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getTotalWeight() {
    const totalWeight = this.globals.Store.Customer.Cart.Items.reduce<number>((previousValue: number, item: CartItem, index: number, arr: CartItem[]) => {
      previousValue += item.Product.Weight * item.Quantity;

      return previousValue;
    }, 0);

    return totalWeight;
  }

  @HostListener('window:beforeunload')
  onUnload() {
    console.log('Before unloading');
  }
}

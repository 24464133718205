import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { CartService } from '../_services/cart.service';
import { CustomerService } from '../_services/customer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CartItem } from '../_models/cart-item';
declare let fbq: Function;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor(
    private router: Router,
    public globals: Globals,
    public snackBar: MatSnackBar,
    public cartService: CartService,
    public customerService: CustomerService
  ) { }

  isUpdating = false;

  ngOnInit(): void { }

  removeFromCart(ref: any) {
    this.isUpdating = true;
    this.cartService.removeFromCart(ref)
      .finally(() => { this.isUpdating = false; });
  }

  checkout() {
    fbq('track', 'InitiateCheckout');
    this.cartService.cartUpdate(this.globals.Store.Customer.Cart);
    this.globals.goToUrl(this.router, 'checkout');
  }

  onQuantityChange(item: CartItem, value: string ) {
    console.log('QuantityChange: ' + value);
    if (item.Quantity <= 0) {
      item.Quantity = 1;
    }
    this.cartService.cartItemUpdate(this.globals.Store.Customer.Cart.Id, item.Id, item.Product.Id, item.Quantity);
  }

  continue() {
    this.globals.goToUrl(this.router, 'catalog');
  }
}

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';

@Injectable()
export class NeedAuthGuard implements CanActivate {

  constructor(
    public globals: Globals,
    private router: Router
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let store = route.params.store;
    if (this.globals.isNullOrEmpty(store) || store.toLowerCase() === 'home') {
      this.router.navigate(['home']);
      this.globals.Store.Name = '';
    }

    if (this.globals.isNullOrEmpty(this.globals.Store.Name) || this.globals.Store.Name !== store) {
      store = store.toLowerCase();
      console.log('Set current store: ' + store);
      this.globals.Store.Name = store;
      localStorage.setItem('store', this.globals.Store.Name);
    }
    return true;
  }
}

import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Globals } from '../globals';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public headerContainerBackgroundColor: string;
  public showSearchMini = false;
  public visible = false;
  public animate = false;
  public vendorConfig = {
    ProductListFormat: 'grid',
    HeaderBackgroundColor: '#000000',
    FooterText: '© UNO 2020. All Rights Reserved',
    HeaderForegroundColor: '#F1F1F1',
    HeaderLogo: 'assets/img/premier-logo.png',
    ButtonBackgroundColor: '#f44336',
    ButtonForegroundColor: '#ffffff',
    PriceColor: '#f57224',
    EnableSlider: false,
    EnableSalesPopup: false,
    SlideTimeout: 3000,
    Slides: [],
    SocialMediaLinks: {
      Facebook: '',
      Twitter: '',
      Youtube: '',
      Instagram: '',
    },
  };

  constructor(public globals: Globals, public authService: AuthService, private router: Router) {
    this.vendorConfig = this.globals.Store.VendorConfig;
    this.globals.cartItemsCount.subscribe((result) => {
      this.animate = result;
    });
  }

  public changeCategory(value) {
    this.globals.Store.SelectedCategory = +value;
    this.globals.goToUrl(this.router, 'catalog?category=' + value);
  }

  public ngOnInit(): void {
  }

  public openCatalog(): void {
    this.globals.goToUrl(this.router, 'catalog');
  }

  public toggleSearchPanel() {
    this.showSearchMini = !this.showSearchMini;
  }
}

<div class="card border-0 product" style="padding:10px 15px;">
	<div class="imgContainer">
		<img title="{{product.Name}}" loading="lazy" class="productImg" alt="{{product.Name}}"
			data-src="{{'noimage.png' | imageUrl}}" *ngIf="!product.ImageUrl" />
		<img title="{{product.Name}}" loading="lazy" class="productImg" alt="{{product.Name}}"
			data-src="{{product.ImageUrl | imageUrl}}" *ngIf="product.ImageUrl" (click)="showProduct(product.Id)" />
	</div>
	<div class="productContent__container">
		<p class="productContent__name" (click)="showProduct(product.Id)">
			{{product.Name}}
		</p>
		<div *ngIf="product.ListPrice > 0">
			<span class="previousPrice strikethrough">{{ globals.Store.Currency }} {{product.ListPrice | number:'1.2-2'
				}}</span>
		</div>
		<span class="productContent__price" [style.color]="globals.Store.VendorConfig.PriceColor">{{
			globals.Store.Currency }} {{product.Price | number:'1.2-2' }}</span>
		<div class="productContent__buttonContainer" *ngIf="!isUpdating">
			<button class="productContent__button" mat-raised-button
				[style.backgroundColor]="globals.Store.VendorConfig.ButtonBackgroundColor"
				[style.color]="globals.Store.VendorConfig.ButtonForegroundColor" (click)="addItem(product)">ADD TO
				CART</button>
		</div>
	</div>
</div>
<div *ngIf="product" class="container p-0">
    <mat-card style="margin-top: 10px;">
        <div class="card flex-md-row mb-4 h-md-250 border-0">

            <div class="card-body d-flex flex-column align-items-start">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <img class="flex-auto" alt="{{product.Name}}"
                            data-src="{{'images/products/noimage.png' | imageUrl}}"
                            *ngIf="!product.ImageUrl" />
                        <img class="flex-auto" alt="{{product.Name}}"
                            data-src="{{product.ImageUrl | imageUrl}}"
                            *ngIf="product.ImageUrl" />
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="p-0">
                            <strong class="d-inline-block mb-2 text-primary">{{product.Name}}</strong>
                            <p class="card-text mb-auto">{{product.Description}}</p>
                        </div>
                        <div class="p-0" *ngFor="let item of product.Content">
                            <div [innerHTML]="item.Data | sanitizedHtml"></div>
                        </div>
                        <div>
                            <div class="mt-2" *ngIf="product.ListPrice > 0">
                                <span
                                    class="previousPrice strikethrough">{{ globals.Store.Currency }} {{product.ListPrice | number:'1.2-2' }}</span>
                            </div>
                            <div class="text-left" style="padding-top: 10px;">
                                <span class="price"
                                    [style.color]="globals.Store.VendorConfig.PriceColor">{{ globals.Store.Currency }} {{product.Price | number:'1.2-2' }}</span>
                            </div>
                            <div class="container">
                                <div class="add-cart text-center text-md-left my-4 row" *ngIf="!isUpdating">
                                    <button class="w-100 mr-3 col font-width" style="max-width:300px" mat-raised-button
                                        [style.backgroundColor]="globals.Store.VendorConfig.ButtonBackgroundColor"
                                        [style.color]="globals.Store.VendorConfig.ButtonForegroundColor"
                                        (click)="addItem(product)">ADD TO CART</button>
    
                                    <button class="w-100 col font-width" style="max-width:300px" mat-raised-button
                                        [style.backgroundColor]="globals.Store.VendorConfig.ButtonBackgroundColor"
                                        [style.color]="globals.Store.VendorConfig.ButtonForegroundColor"
                                        (click)="buyNow(product)">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </mat-card>
</div>
<ng-container *ngIf="globals.Store.VendorConfig.EnableSalesPopup">
    <app-sales-pop-up></app-sales-pop-up>
</ng-container>
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from './globals';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  constructor(public globals: Globals) { }
  transform(value: any): unknown {
    if (value.startsWith('http')) {
      return value;
    }

    if (value.startsWith('/')) {
      return this.globals.Store.ProductsImageUrl + value.substring(1);
    }
    return this.globals.Store.ProductsImageUrl + value;
  }

}

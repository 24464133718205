import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from './../globals';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  public tokenKey = 'public-apps';
  public clientId = 'public-apps';

  constructor(private http: HttpClient, public globals: Globals) {
    this.token = localStorage.getItem(this.tokenKey);
  }

  public token: string;

  getToken() {
    if (this.isLogged()) {
      return localStorage.getItem(this.tokenKey);
    }
    return null;
  }

  login(username: string = '', password: string = ''): Promise<any> {
    username = 'pQwJ71SWzLt1';
    password = '95SxspDhOc!*x^%';
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');
    params.append('client_id', this.clientId);
    const headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8' });
    const options = { headers };

    return this.http
      .post(environment.authUrl, params.toString(), options)
      .toPromise()
      .then((res) => {
        // login successful if there's a jwt token in the response
        const token = (res as JWT).access_token;

        if (token) {
          // set token property
          this.token = token;

          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.tokenKey, token);

          // return true to indicate successful login
          return true;
        } else {
          // return false to indicate failed login
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem(this.tokenKey);
    this.token = null;
  }

  isLogged() {
    const ltoken = localStorage.getItem(this.tokenKey);
    return ltoken != null && ltoken !== '';
  }
}

export class JWT {
  // tslint:disable-next-line: variable-name
  access_token: string;
  // tslint:disable-next-line: variable-name
  token_type: string;
  // tslint:disable-next-line: variable-name
  expires_in: string;
}
